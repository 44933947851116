import {
  Box,
  Typography,
  ImageList,
  ImageListItem,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import { images } from "./images.ts";
import "react-18-image-lightbox/style.css";
import { Container } from "../../../../components/Container.js";
import { Instagram } from "@mui/icons-material";

export const Gallery = () => {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box>
      <Box
        sx={{
          backgroundImage:
            "url(https://madmoose.tours/wp-content/uploads/2022/11/Night-UTV-Tour20221116_0012.webp)",
          backgroundPosition: "center",
        }}
      >
        <Box sx={{ background: "rgba(0,0,0,0.7)" }}>
          <Container
            sx={{
              py: { xs: 10, md: 20 },
            }}
          >
            <Box textAlign="center">
              <Typography variant="h5">
                No te pierdas todas las novedades
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() =>
                  window.open("https://www.instagram.com/utvargentina/")
                }
              >
                <Instagram fontSize="medium" sx={{ mr: 1 }} />
                Seguinos
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>

      <ImageList
        sx={{
          width: "100%",
          maxWidth: "100%",
          mt: 0,
          mb: 0,
        }}
        // cols={isXs ? 2 : isMd ? 4 : isLg ? 6 : 8}
        cols={isLg ? 6 : isMd ? 4 : isXs ? 2 : 4}
        gap={0}
      >
        {images.map((item, index) => (
          <ImageListItem key={item.src + index}>
            <img
              srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
              onClick={() => handleClick(images.indexOf(item), item)}
              loading="lazy"
              style={{ maxHeight: 400 }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </Box>
  );
};
