import { Box } from "@mui/material";
import { Hero } from "./components/Hero";
import { Gallery } from "./components/Gallery";

export const Home = () => {
  return (
    <Box>
      <Hero />
      <Gallery />
    </Box>
  );
};
