import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import { WhatsApp, Check } from "@mui/icons-material";
import Logo from "../../../../assets/logo.png";
import Bg from "./image.png";

export const Hero = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          py: 30,
          pt: 32,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="Logo"
            sx={{
              maxWidth: { xs: 300, md: 500 },
              display: "block",
            }}
          />

          <Box mt={5} gap={2} display="flex">
            <Button variant="contained" onClick={() => window.open('https://forms.gle/gFZsCFJqE5WkZ3d97')}>
              <Check fontSize="medium" sx={{ mr: 1 }} />
              Anotarse
            </Button>
            <Button
              onClick={() => window.open("https://wa.me/5491164643030")}
              variant="outlined"
              color="inherit"
            >
              <WhatsApp fontSize="medium" sx={{ mr: 1 }} />
              Hablemos
            </Button>
          </Box>
        </Box>
      </Box>
      <Marquee
        style={{
          borderTop: "1px solid rgba(255, 255, 255, 0.2)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <Box
          display="flex"
          sx={{
            py: 3,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 38 },
            }}
          >
            AMANTES DE LOS FIERROS
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 38 },
            }}
          >
            TRAVESIAS - NOCTURNAS 4X4
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 38 },
            }}
          >
            UTV - ATV - MOTOCROSS
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 38 },
            }}
          >
            PINAMAR #UTVARGENTINA
          </Typography>
        </Box>
      </Marquee>

      <Box
        component="img"
        src={Bg}
        sx={{
          position: "absolute",
          width: { xs: "auto", md: "100%" },
          height: { xs: "100%", md: "auto" },
          top: 0,
          left: 0,
          // background: "rgba(18,18,18,0.6)",
          opacity: { xs: 0.2, md: 0.1 },
          filter: "grayscale(1)",
          zIndex: -2,
        }}
      />
      {/* <Box
        component="video"
        autoPlay
        // poster={Preview}
        loop
        muted
        style={{
          position: "absolute",
          width: "auto",
          height: "100%",
          left: 0,
          top: 0,
          zIndex: -3,
          ...(isNotMobile && {
            width: "100%",
            height: "auto",
            top: "-60%",
          }),
        }}
      >
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </Box> */}
      <Box
        component="iframe"
        // width="400"
        // height="400"
        src="https://www.youtube.com/embed/jSuNQNByfKI?si=QS5S1-wWaxfnP3el&autoplay=1&mute=1&playsinline=1&controls=0&playlist=jSuNQNByfKI&loop=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        style={{
          position: "absolute",
          width: "100%",
          height: "120%",
          marginTop: "-20%",
          left: 0,
          top: 0,
          zIndex: -3,
          ...(isNotMobile && {
            marginTop: "0%",
          }),
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: 300,
          backgroundImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
      />
    </Box>
  );
};
