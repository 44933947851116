import img1 from "./assets/1.jpg";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";
import img6 from "./assets/6.png";
import img7 from "./assets/7.png";
import img8 from "./assets/8.png";

export const images = [
  {
    src: img1,
    original: img1,
  },
  {
    src: img2,
    original: img2,
  },
  {
    src: img3,
    original: img3,
  },
  {
    src: img4,
    original: img4,
  },
  {
    src: img5,
    original: img5,
  },
  {
    src: img6,
    original: img6,
  },
  {
    src: img7,
    original: img7,
  },
  {
    src: img8,
    original: img8,
  },
];
