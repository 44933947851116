import { Container as ContainerBase } from "@mui/material";

export const Container = ({ children, sx = {}, component = "div" }) => (
  <ContainerBase
    maxWidth="lg"
    component={component}
    sx={{
      py: 8,
      ...sx,
    }}
  >
    {children}
  </ContainerBase>
);
